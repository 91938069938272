import * as React from "react";

export default function PasswordIcon(props: IconProps) {
  return (
    <svg
      width={17}
      height={23}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.037 7.722H4.963v-2.74c0-2.048 1.587-2.716 3.537-2.716s3.537.667 3.537 2.716v2.74Zm-8.755-2.74C3.282 1.96 5.622.5 8.5.5c2.877 0 5.218 1.46 5.218 4.482v2.74h1.04m0 0C15.995 7.722 17 8.78 17 10.077v10.068c0 1.298-1.005 2.355-2.241 2.355H2.24C1.006 22.5 0 21.444 0 20.145V10.077C0 8.78 1.005 7.722 2.241 7.722h1.041v-2.74M2.242 9.49c-.31 0-.56.264-.56.588v10.068c0 .325.25.589.56.589h12.517c.309 0 .56-.264.56-.589V10.077c0-.324-.251-.588-.56-.588H2.24Z"
      />
    </svg>
  );
}
